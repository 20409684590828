import React from 'react';

function Features() {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: '#1b1f27',
          zIndex: '1!important'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
            zIndex: '99999!important'
          }}
        >
          <div
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0
            }}
          />
          <div
            style={{
              color: '#fff',
              fontSize: '3.5em',
              zIndex: 10
            }}
          >
            <span style={{ color: '#fff' }}>risk</span>
            <span
              style={{
                background: '#457fc2',
                padding: '0px 10px',
                color: '#fff',
                fontWeight: 600,
                marginLeft: 2
              }}
            >
              FORCE
            </span>
          </div>
          <div
            style={{
              textTransform: 'uppercase',
              color: '#d5d5d5',
              zIndex: 10,
              fontSize: '1.2rem',
              fontWeight: 600
            }}
          >
            Purpose built risk tool for Prop Firms & Funding Evaluators.
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
