import { api } from '../../api';
import {
  CONFIRM_USER,
  LOGIN_USER,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER_FAILURE,
  REGISTER_USER_STARTED,
  REGISTER_USER_SUCCESS,
  SESSION_LOAD,
  UPDATE_USER_FAILURE,
  UPDATE_USER_STARTED,
  UPDATE_USER_SUCCESS,
  USER_LOADED
} from '../actions';

export const loginUser = (email, password, onStateChange) => {
  return (dispatch, getState) => {
    dispatch(loginUserStarted());
    return api
      .post('/auth/login', { username: email, password })
      .then(res => res.data)
      .then(loginData => {
        dispatch(loginUserSuccess(loginData.user));

        return loginData.token;
      })
      .catch(err => {
        if (err && err.response.data.code === 'UserNotConfirmedException') {
          dispatch(loginUserFailure(null));
          dispatch(userConfirmation(email));

          throw err;
        } else if (err && err.response.data.code === 'NotAuthorizedException') {
          dispatch(loginUserFailure({ message: 'Invalid email or password!' }));
          dispatch(userConfirmation(email));

          throw err;
        } else {
          if (err && err.response && err.response.data) {
            dispatch(loginUserFailure(err.response.data));
          } else {
            dispatch(loginUserFailure(err));
          }

          throw err;
        }
      });
  };
};

const loginUserStarted = () => ({
  type: LOGIN_USER
});

export const loginUserSuccess = user => ({
  type: LOGIN_USER_SUCCESS,
  payload: {
    user
  }
});

const loginUserFailure = message => ({
  type: LOGIN_USER_FAILURE,
  payload: {
    message
  }
});

const userLoaded = user => ({
  type: USER_LOADED,
  payload: {
    user
  }
});

export const userConfirmation = email => ({
  type: CONFIRM_USER,
  payload: {
    email
  }
});

export const registerUser = (values, onStateChange) => {
  return (dispatch, getState) => {
    dispatch(registerUserStarted());
    return api
      .post('/register', {
        email: values.email,
        password: values.password,
        given_name: values.first_name,
        family_name: values.last_name,
        name: values.company_name
      })
      .then(user => {
        dispatch(registerUserSuccess(user));
        return user;
      })
      .catch(err => {
        dispatch(registerUserFailure(err.response.data.error));
        console.log(err);
        throw err;
      });
  };
};

export const registerUserStarted = () => ({
  type: REGISTER_USER_STARTED
});
export const registerUserSuccess = user => ({
  type: REGISTER_USER_SUCCESS,
  payload: {
    user
  }
});
export const registerUserFailure = message => ({
  type: REGISTER_USER_FAILURE,
  payload: {
    message
  }
});

export const logoutUser = history => ({
  type: LOGOUT_USER,
  payload: { history }
});

export const sessionLoad = currentUser => ({
  type: SESSION_LOAD,
  payload: { currentUser }
});

export const updateUser = (values, patch) => {
  return (dispatch, getState) => {
    dispatch(updateUserStarted());
    const method = patch ? 'patch' : 'put';
    return api[method]('/users/me', values)
      .then(response => response.data)
      .then(user => user.user)
      .then(user => {
        dispatch(updateUserSuccess(user));
        return user;
      })
      .catch(err => {
        dispatch(updateUserFailure(err.response.data.error));
      });
  };
};

export const updateUserStarted = () => ({
  type: UPDATE_USER_STARTED
});
export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: {
    user
  }
});
export const updateUserFailure = message => ({
  type: UPDATE_USER_FAILURE,
  payload: {
    message
  }
});
