import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Alert, Button, CardTitle, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import ReactstrapFormikInput from '../../components/forms/ReactstrapFormikInput';
import useToken from '../../helpers/useToken';
import UserLayout from '../../layout/UserLayout';
import { loginUser } from '../../redux/auth/actions';
import Features from './features';
import './login-styles.css';

const Login = ({ onStateChange, loginUser, loading, error, history }) => {
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const { state } = useLocation();
  const { setToken } = useToken();

  const goToSignUp = () => {
    history.push('/user/register');
  };

  const goToConfirm = () => {
    history.push('/user/confirm');
  };

  const goToForgotPassword = () => {
    history.push('/user/forgot-password');
  };

  function attemptLogin(values) {
    loginUser(values, onStateChange)
      .then(token => {
        setToken(token);
      })
      .then(() => setRedirectToReferrer(true))
      .catch(err => {
        if (err.code === 'UserNotConfirmedException') {
          goToConfirm(values);
        }
      });
  }

  if (redirectToReferrer === true) {
    return <Redirect to={state?.from || '/app'} />;
  }

  return (
    <div>
      <UserLayout>
        <Row style={{ marginRight: 0, marginLeft: 0, height: '100vh' }}>
          <Colxx lg='3' md='12' sm='12' style={{ background: '#1f242e' }}>
            <div style={{ height: '100vh', padding: 40, display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '100%' }}>
                <div
                  className='logo-company-name'
                  style={{ fontSize: '1.3rem', marginBottom: 20, color: '#fff', fontWeight: 600 }}
                >
                  <img src='../assets/img/logo.png' style={{ width: 60 }} />
                </div>
                <CardTitle className='mb-4' style={{ color: '#fff' }}>
                  Sign in
                </CardTitle>
                <Formik
                  initialValues={{ email: '', password: '' }}
                  onSubmit={values => attemptLogin(values)}
                >
                  {props => (
                    <Form>
                      <span style={{ color: '#ffee' }}>Email (case sensitive)</span>
                      <Field
                        component={ReactstrapFormikInput}
                        type='email'
                        name='email'
                        required
                        className='mb-4'
                      />

                      <span style={{ color: '#ffee' }}>Password</span>
                      <Field
                        component={ReactstrapFormikInput}
                        type='password'
                        name='password'
                        className='mb-4'
                      />

                      {error && (
                        <Alert color='danger' style={{ color: '#fff', border: '1px solid red' }}>
                          {error.error}
                        </Alert>
                      )}
                      <div style={{ display: 'flex' }}>
                        <Button
                          className='mt-2'
                          style={{ borderRadius: 5, backgroundColor: '#438eff', width: '100%' }}
                          size='lg'
                          type='submit'
                          disabled={loading}
                        >
                          Sign In
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Colxx>
          <Colxx lg='9' className='d-none d-lg-block' style={{ background: '#1f242e' }}>
            <Features />
          </Colxx>
        </Row>
      </UserLayout>
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.auth.loading,
  error: state.auth.error
});

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (values, onStateChange) =>
      dispatch(loginUser(values.email, values.password, onStateChange))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
