import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { Button, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Colxx } from '../../components/common/CustomBootstrap';
import ReactstrapFormikInput from '../../components/forms/ReactstrapFormikInput';
import { successMessage } from '../../helpers/Utils';
import UserLayout from '../../layout/UserLayout';
import { registerUser } from '../../redux/auth/actions';
import Features from './features';
import './login-styles.css';

const SignupSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Please enter 2 or more characters!')
    .max(100, 'Too Long!')
    .required('Please enter your first name!'),
  last_name: Yup.string()
    .min(2, 'Please enter 2 or more characters!')
    .max(100, 'Too Long!')
    .required('Please enter your last name!'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .required('Please choose a password!'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Please enter your email address!')
});

const UserSignupSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Please enter 2 or more characters!')
    .max(100, 'Too Long!')
    .required('Please enter your first name!'),
  last_name: Yup.string()
    .min(2, 'Please enter 2 or more characters!')
    .max(100, 'Too Long!')
    .required('Please enter your last name!'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .required('Please choose a password!'),
  screenName: Yup.string()
    .min(2, 'Too Short!')
    .required('Please choose a screen-name!'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Please enter your email address!'),
  screenName: Yup.string()
    .min(2, 'Please enter 2 or more characters!')
    .max(100, 'Too Long!')
    .required('Please enter a screen name!')
});

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCompany: !props.inviteId
    };
  }

  goToLogin = () => {
    this.props.history.push('/user/login');
  };

  getEmail = values => {
    return !this.props.loading && this.props.invite ? this.props.invite.email : values.email;
  };

  onUserRegister = (values, props) => {
    const { registerUser, onStateChange } = this.props;

    registerUser(
      {
        ...values,
        email: this.getEmail(values)
      },
      onStateChange
    )
      .then(() => {
        successMessage('Account created! Please log in.');
        this.goToLogin();
      })
      .catch(err => {
        const response = err && err.response ? err.response.data : {};
        const prop =
          response && response.error && response.error.match(/email/gi) ? 'email' : 'password';

        props.setErrors({
          [prop]: response.error
        });
      })
      .finally(() => {
        props.setSubmitting(false);
      });
  };

  render() {
    const { isCompany } = this.state;
    const { invite, loading, inviteId } = this.props;

    return (
      <div className='h-100'>
        <UserLayout>
          <Row style={{ marginRight: 0, marginLeft: 0, height: '100vh' }}>
            <Colxx lg='3' sm='12' md='12' style={{ background: '#141414' }}>
              <div style={{ height: '100vh', padding: 40, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                  <SkeletonTheme
                    color='rgba(255, 255, 255, 0.2)'
                    highlightColor='rgba(255, 255, 255, 0.3)'
                  >
                    <div
                      className='logo-company-name'
                      style={{
                        fontSize: '1.3rem',
                        marginBottom: 20,
                        color: '#fff',
                        fontWeight: 600
                      }}
                    >
                      <span style={{ fontWeight: 600 }}>TradeDuels</span>
                    </div>
                  </SkeletonTheme>
                  <Formik
                    initialValues={{
                      email: this.getEmail({ email: '' }),
                      first_name: '',
                      last_name: '',
                      screenName: '',
                      password: ''
                    }}
                    validationSchema={isCompany ? SignupSchema : UserSignupSchema}
                    onSubmit={this.onUserRegister}
                  >
                    {props => {
                      const { setFieldValue, errors, values, touched } = props;

                      if (!loading && invite && !values.email) {
                        setFieldValue('email', invite.email);
                      }

                      const evaluations = [
                        { value: '1', label: 'Level I' },
                        { value: '2', label: 'Level II' },
                        { value: '3', label: 'Level III' }
                      ];

                      return (
                        <Form>
                          <div style={{ color: '#ffee', marginTop: 10 }}>First Name</div>
                          <Field component={ReactstrapFormikInput} type='text' name='first_name' />
                          <div style={{ color: '#ffee', marginTop: 10 }}>Last Name</div>
                          <Field component={ReactstrapFormikInput} type='text' name='last_name' />
                          <div style={{ color: '#ffee', marginTop: 10 }}>Email</div>
                          <Field component={ReactstrapFormikInput} type='email' name='email' />
                          <div style={{ color: '#ffee', marginTop: 10 }}>Screen Name</div>
                          <Field
                            component={ReactstrapFormikInput}
                            type='screenName'
                            name='screenName'
                          />
                          <div style={{ color: '#ffee', marginTop: 10 }}>Password</div>
                          <Field
                            component={ReactstrapFormikInput}
                            type='password'
                            name='password'
                          />
                          <div className='mt-4 mb-4' style={{ display: 'flex' }}>
                            <Button
                              disabled={props.isSubmitting}
                              type='submit'
                              className='mr-2 mt-2 action-button'
                              style={{
                                position: 'relative',
                                borderRadius: 5,
                                borderColor: '#393939'
                              }}
                            >
                              Create Account
                            </Button>
                            {!invite && (
                              <Button
                                type='reset'
                                onClick={this.goToLogin}
                                color='light'
                                className='mt-2 ml-2'
                                style={{
                                  borderRadius: 5
                                }}
                              >
                                Sign In instead
                              </Button>
                            )}
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </Colxx>
            <Colxx
              lg='9'
              className='d-none d-lg-block'
              style={{ background: `url('/assets/img/bg.png')`, backgroundSize: 'cover' }}
            >
              <Features />
            </Colxx>
          </Row>
        </UserLayout>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.auth.loading || state.loading.models.invites,
  user: state.auth.user
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    registerUser: (values, onStateChange) => dispatch(registerUser(values, onStateChange))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
